/* App.css */

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.Header {
  background-color: #282c34;
  color: white;
  padding: 10px;
}

.App-content {
  display: flex;
  flex-grow: 1; /* Main content area grows to fill available space */
}

.Sidebar {
  position: 'inherit'
}

.Sidebar.minimized {
  width: 50px;
}

.Sidebar ul {
  list-style-type: none;
  padding: 0;
}

.Sidebar li {
  padding: 10px 20px;
}

.Sidebar li:hover {
  background-color: #444;
}

.Sidebar a {
  color: white;
  text-decoration: none;
}

.MainContent {
  flex-grow: 1; /* Main content area grows to fill available space */
  padding: 20px;
  background-color: #f0f0f0; /* Add a background color for the main content */
  overflow-y: auto; /* Add scrollbar if content overflows */
}

.Footer {
  background-color: #282c34;
  color: white;
  padding: 10px;
}
